import React, { Component, useState, useEffect, useRef  } from 'react'
import ReactDOM from 'react-dom'

import Typekit from 'react-typekit';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEquals, faAngleUp, faAngleDown, faAnglesUp, faAnglesDown, faChevronDown, faCircleNotch, faSpinner, faTriangleExclamation  } from '@fortawesome/free-solid-svg-icons'

//import { Sparklines, SparklinesLine, SparklinesSpots,  SparklinesBars  } from 'react-sparklines';

import Select, { components, OptionProps  } from 'react-select';
//https://stackoverflow.com/questions/52503732/how-do-you-create-optgroups-in-react-select-v2

import makeAnimated from 'react-select/animated'

//import 'bootstrap/dist/css/bootstrap.min.css'
import './App.scss'

import { DropdownButton, Dropdown } from 'react-bootstrap';
import { MenuItem } from 'react-bootstrap';
import { propTypes } from 'react-bootstrap/esm/Image';

import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';

import { jsPDF } from "jspdf";
import { CSVLink, CSVDownload } from "react-csv";

import { agreement }  from "./licenceAgreement.js"

const typeKitId = "iwx4ajy";

const animatedComponents = makeAnimated()

const queryParams = new URLSearchParams(window.location.search)

let debug = queryParams.get("logs") || 0;
const log = debug ? console.log.bind(console) : function () {}

const urlParamArea = queryParams.get("area") || null
const urlParamDrilldown = queryParams.get("drilldown") || null
const urlParamAreaSelect = queryParams.get("areaSelect") || null

const apiHostname = queryParams.get("apihostname") || "benefitapi.shrm.org" 
const urlStyles = {}
queryParams.get("bodyFontSize") && (urlStyles.bodyFontSize = queryParams.get("bodyFontSize"))
queryParams.get("primary") && (urlStyles.primary = '#' + queryParams.get("primary"))
queryParams.get("success") && (urlStyles.success = '#' + queryParams.get("success"))
queryParams.get("danger") && (urlStyles.danger = '#' + queryParams.get("danger")) 
queryParams.get("gray") && (urlStyles.gray = '#' + queryParams.get("gray")) 
queryParams.get("borderRadius") && (urlStyles.borderRadius = queryParams.get("borderRadius")) 



const urlParamAreaObject = urlParamArea ? {label: urlParamArea, value: urlParamArea} : null
const urlParamDrilldownObject = urlParamDrilldown ? {label: urlParamDrilldown, value: urlParamDrilldown} : null
log('url params', urlParamAreaObject, urlParamDrilldownObject)



const majorsEndppoint = "https://"+ apiHostname +"/benefits?benefit_major_category="+ urlParamArea +"&industry=0&organization_size=0&full_load=true";

// generated by https://www.mockaroo.com/


const getAPIdata = async () => {
    const url = majorsEndppoint
    const response2 = await fetch(url)
    const json = await response2.json()
    log('getAPIdata()', url, json)
    return json
}

//const resultsEndppoint = endpointPrefix + "api/read_file.php?file=https://bgebert.github.io/ceros-embed/calculated_merged.json&res=1";
const resultsEndppoint = "https://"+ apiHostname +"/benefits?";

const contentResized = () =>{
    //log('uuuu', document.documentElement.offsetHeight, window.parent)
    window.parent && window.parent.postMessage(JSON.stringify({
        "SHRMiframeSays": 1,
        "iframeHeight" : document.documentElement.offsetHeight,
        "src" : window.location.href,
        "ref": document.referrer,
        "wname": window.name 
    }), "*");

    setTimeout(() =>{
        let hiddenTitle = document.querySelector('.export-title')
        let hiddenSlider = hiddenTitle.querySelector('.h-slider') 
    
        hiddenTitle && hiddenSlider && ( hiddenSlider.style.marginBottom = -hiddenTitle.offsetHeight + 'px' )
    }, 100)
    

}
window.addEventListener('resize', contentResized)

function HexToRg(hex){
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? ( parseInt(result[1], 16) +','+ parseInt(result[2], 16) + ',' + parseInt(result[3], 16) ) : "";
  }

function UrlStylesComp(props){
    log('urlStyles', urlStyles )

    let css = `:root{
        `+ Object.entries(urlStyles).map(([key, value]) => (                           
            '--bs-'+ key.replace(/[A-Z]/g, m => "-" + m.toLowerCase()) +':' + value + ';' + ( ["primary", "danger", "success", "grey"].includes(key) ? '--bs-'+ key.replace(/[A-Z]/g, m => "-" + m.toLowerCase()) +'-rgb :' + HexToRg(value) + ';' : '' )
        )).join('') +`
        
    }`
    if(Object.keys(urlStyles).lengt !==0) 
    return (
        <style className='no-export'>
        {css}
        </style>
    )
    
    return ''
}

 const CustomOption = props => {
    return (        
        <components.Option {...props}>
            <span key={props.innerProps.key} data-value={props.data.label.toLowerCase() || ""} >
                {props.data.label}
            </span>
        </components.Option>
        
    );
};

const RenderResult = (props) => {

    
   
    
    const [userData, setUserData] = useState(false)
    const [loading, setLoading] = useState(true)

    

    if(props.options.data){
        log('RenderResult props', props)
    }

    let catName = (props.options.data.eventCategory || {}).value || urlParamArea || null
    let subCatName = (props.options.data.eventLabel || {}).value || urlParamDrilldown || null
    let industryId = props.options.data.selectedIndustry.value || 0
    let orgSize = (props.options.data.selectedOrgSize || {}).value || 0
    let location = (props.options.data.selectedLocation || {}).value || 0
    let columns = props.options.data.tHeaders 

    useEffect(() => {         
        if(catName && subCatName)
        try{
            setLoading(true)
            //getDataWithFetch(resultsEndppoint + "catName=" + catName + "&subCatName=" + subCatName) 
            getDataWithFetch(resultsEndppoint + "benefit_major_category="+ catName + ( subCatName ? "&benefit_category="+ subCatName : "") +"&location="+ location +"&industry="+ industryId +"&organization_size="+ orgSize )

        }catch(error){
            setLoading(false) 
            log('Fetch Error:', error)
        }     
    }, [catName, subCatName, industryId, orgSize, location])


    const getDataWithFetch = async(doc) => {
        
        const response = await fetch(doc)
        const jsonDataAll = await response.json()

        const jsonData = jsonDataAll.Benefits

        log('jsonData0', jsonData)        

        setUserData(jsonData);
        contentResized() 
        subCatName && setLoading(false) 

        props && jsonData && props.setStateOfParent({"currentData": jsonData}) // propagate to the parent component
        
        
    };

    log('userData2', userData) 
      
    
    /* if(loading) return (
        <div className='preloader shrm-loading in mt-1'>Loading...</div>
    ) */

    const getIcon = (indicator) => {
        if(Number(indicator) === 2){
            return faAnglesUp;
        }
        else if(Number(indicator) === -2){
            return faAnglesDown;
        }
        else if(Number(indicator) > 0){
            return faAngleUp;
        }
        else if(Number(indicator) < 0){
            return faAngleDown;
        }
        else{
            return faEquals;
        }
    }

    return (
        <>
        <Typekit kitId={typeKitId} /> 
        <div className="App"> 

            {typeof userData === 'object' ? <div className={'export-title pt-3 loading-' + loading}>
                <p className="the-title my-0 text-center">
                    {/* <span>Benefits</span>
                    <span>{catName}</span> 
                   */}
                    <span>{(props.options.data.eventLabel || {}).label}</span>
                    <span className='text-nowrapX' data-append=" Industries" data-label={(props.options.data.selectedIndustry || {}).label}>{(props.options.data.selectedIndustry || {}).label}</span>
                    <span className='text-nowrap' data-append=" Org Sizes" data-label={(props.options.data.selectedOrgSize || {}).label}>{(props.options.data.selectedOrgSize || {}).label}</span>
                    <span className='text-nowrap' data-append=" Locations" data-label={(props.options.data.selectedLocation || {}).label}>{(props.options.data.selectedLocation || {}).label}</span>
                </p>
                <div className="h-slider no-export"></div>
            </div> : ''}            

            <div className={'result-list  py-3 mt-1 shrm-loading loading-' + loading + ' ' + (loading && 'in')} id={props.id}>
                {typeof userData === 'object' ?                   
                    <>
                    <div className='result-header'>
                        <div className='label'><span>{catName || 'All'}</span></div>
                        {Object.entries(columns).map(([key, value]) => (                           
                            <div key={key} data-key={key} data-label={value} className="the-column"><div><span>{value}</span></div></div>                             
                        ))}
                        {/* <div className='trend'><span>5 years trend</span></div> */}

                    </div> 
                    {userData.map((item, index) => (                        
                        <div key={item+index} className='data-item'>
                            <div className='label'><span>{item.type_label}</span></div>
                            {Object.entries(columns).map(([key, value]) => (                           
                                
                                <React.Fragment key={'f_'+ item+key+index}>
                                    {key ==="delta" && <div key={'hr-' + item+key+index} className='hr d-none'></div>}
                                    <div data-label={value} data-key={key} data-value={item[key]} className="the-column"><div><span>{item[key]}</span></div></div>                                
                                </React.Fragment>
                                                         
                            ))}
                            
                            <div className='the-column indicator' data-value={item.indicator}>
                                
                                <div>{item.indicator &&
                                    <FontAwesomeIcon icon={getIcon(item.indicator)} />
                                }</div>
                                
                            </div>                            
                            {/* <div className='the-column sparkline_data' data-value={item.sparkline_data}><div>
                                <Sparklines data={item.sparkline_data.split(',')} >
                                    <SparklinesLine  color="var(--bs-gray-600, #dddddd)"  />
                                    <SparklinesSpots  size={5} />
                                </Sparklines>
                            </div></div> */}
                        </div>                        
                    ))}

                    {typeof userData === 'object' && userData.length === 0 ?                   
                    <>
                        <div className='data-item'>
                            <div className='label'><span>- -</span></div>
                            {Object.entries(columns).map(([key, value]) => (                           
                                
                                <React.Fragment key={'ef_'+ key}>
                                    {key ==="delta" && <div key={'ehr-' + key} className='hr d-none'></div>}
                                    <div data-label={value} data-key={key} data-value="- -" className="the-column"><div><span>- -</span></div></div>                                
                                </React.Fragment>
                                                         
                            ))}                           
                        </div> 
                    </> :
                    <></>}


                    </> : <><div className={"text-center fs-2 my-5 d-" + (loading && "none")} >Ooops, looks like there aren't any matches for your search </div></>
                }
            </div>
            
            <div className="licence-agr small py-4 mb-5 pb-5 px-2 text-muted" dangerouslySetInnerHTML={{__html: agreement}} />   
        </div>
        </>
    );
};


class App extends Component { 
    
    constructor(props) {
        super(props);
        this.setStateOfParent.bind(this);
        this.csvLink = React.createRef();

        this.state = {
            majorCats : [],
            subCats:[],
            industries: [],
            orgSizes:[],
            locations:[],
            regions: [],
            states: [],
            selectedOption: urlParamAreaObject || null, //{label: null, value: null},
            selectedSubOption: urlParamDrilldownObject || null,
            selectedIndustry: {id: 0, label: 'all'},
            selectedOrgSize: {id: 0, label: 'all'},
            selectedLocation: {id: 0, label: 'all'},
            majorCatLabel : 'benefit_major_category', //'eventCategory',
            subMajorCatLabel : 'benefit_category', //'eventLabel', //'sub_cat'
            currentData: [],
            tColumns: {
                "y1": "2020",
                "y2": "2021",
                "y3": "2022",
                "y4": "2023",
                "y5": "2024", 
                "delta" : "% change"
                /* "indicator" : "",
                "sparkline_data" : "Graph"  */
            },
            isExporting: false,
            csvData : []
        };
    }

    // method for setting data from within child components
    setStateOfParent = (newDataSet) => {
        this.setState(newDataSet);
    }


    componentDidMount() {
        getAPIdata()
            .then(res => {
                log('res - didMount', res);
                let stateObj = {
                    majorCats: res.BenefitMajorCategories,
                    subCats: res.BenefitCategories,
                    industries: res.Industries,
                    orgSizes: res.OrgSizes,
                    locations: res.Locations,
                    regions: res.Locations.filter(reg => (reg.optgroup_label === "Region" && reg.id!==0)),
                    states: res.Locations.filter(reg => reg.optgroup_label === "State"),
                    selectedSubOption: {label: (res.BenefitCategories[0] ||{}).benefit_category || <FontAwesomeIcon className={'text-warning'} icon={faTriangleExclamation} />, value: (res.BenefitCategories[0]||{}).id || " "}
                }
                this.setState(stateObj)
                //log("hello", this.state.subCats)                
            }) 
    }   
    
    handleCatChange = selectedOption => {
        this.setState({ selectedOption })
        //this.handleSubCatChange({label: 'All', value: 'all'})
        this.handleSubCatChange()
        log('Option selected2:', selectedOption, this.getSubMajorCats())
        
    }  

    handleSubCatChange = selectedSubOption => {
        this.setState({ selectedSubOption })
        log('Option (sub) selected3:', selectedSubOption)
    };

    handleIndustryChange = selectedIndustry =>{        
        this.setState({ selectedIndustry })
    }
    handleOrgSizeChange = selectedOrgSize =>{        
        this.setState({ selectedOrgSize })
    }
    handleLocationChange = selectedLocation =>{        
        this.setState({ selectedLocation })
        log('handleLocationChange', selectedLocation, this.state)
    }
    
    getMajorCats(){
        const majors = this.state.majorCats.map(function (cat) {
            return { value: cat["id"], label: cat["label"] }
        })
        return majors
    }
    getSubMajorCats(){
        const subMajorCatLabel = this.state.subMajorCatLabel //'sub_cat'

        const subMajors = this.state.subCats.map(function (cat) {
             return  { value: cat["id"], label: cat[subMajorCatLabel] }
        })

        log('getSubMajorCats state', subMajors, this.state)

        return subMajors
    }
    
    getIndustries(){
        const theIndustries = this.state.industries.map(function (cat) {
            return { value: cat["id"], label: cat["label"] }
        })
        return theIndustries
    }

    getOrgSize(){
        const theOrgSizes = this.state.orgSizes.map(function (cat) {
            return { value: cat["id"], label: cat["label"] }
        })
        return theOrgSizes
    }
    getLocation(){
        //log('get Location', this.state)
        const theRegions = this.state.regions.map(function (cat) {
            return { value: cat["id"], label: cat["label"] }
        })
        const theStates = this.state.states.map(function (cat) {
            return { value: cat["id"], label: cat["label"] }
        })

        const dflt = this.state.locations.filter(it => {return it.id === 0}).map(loc => {
            return { value: loc["id"], label: loc["label"] }
        })

        return [dflt[0] ,{label: "Regions", options: theRegions},{label: "States", options: theStates}]
    }

    getColumnsHeaders(){
        return this.state.tColumns
    }

    downloader = (type, e) =>{

        this.setState({
            isExporting: true
        })

        setTimeout(() =>{
            let major = (this.state.selectedOption || urlParamAreaObject || this.getMajorCats()[0]) || null

            const Component = document.querySelector('.App');
           

            const fileName = "SHRM Benefits - " + major.label + ' - ' + this.state.selectedSubOption.label 
            
            

            if(["img","pdf"].includes(type)) {
                Component.classList.add('exporting')

                const filter = (node)=>{
                    const exclusionClasses = ['remove-me', 'secret-div', 'no-export'];

                    const elist = (node.classList||{}).value ? node.classList.value : []
                    return !exclusionClasses.some((classname)=> elist.includes(classname)); 
                  }

                htmlToImage.toPng(Component, { filter: filter, pixelRatio: 1, style: {backgroundColor: "white"}  })
                .then(function (dataUrl) {                     

                    const ddblocks = document.querySelectorAll('.dd-block')
                    for( let i = 0; i < ddblocks.length; i++) {
                        ddblocks[i].classList.remove('dd-block')
                      }

                    const componentWidth = Component.offsetWidth
                    const componentHeight = Component.offsetHeight               
                    
                    const orientation = componentWidth >= componentHeight ? 'l' : 'p'

                    const pdf = new jsPDF( orientation, 'pt' )                    

                    pdf.internal.pageSize.width = componentWidth * (72 / 96)
                    pdf.internal.pageSize.height = componentHeight * (72 / 96)                    
                
                    /* let width = pdf.internal.pageSize.getWidth();
                    let height = pdf.internal.pageSize.getHeight(); */
                    if( type === 'pdf'){
                        pdf.addImage(dataUrl, 'PNG', 0, 0)
                        pdf.save(fileName + ".pdf")  
                    }else if(type === 'img'){
                        const link = document.createElement('a')
                        link.download = fileName + '.png'
                        link.href = dataUrl
                        link.click()  
                    } 

                    Component.classList.remove('exporting')                                   

                })
                .then((response) => this.setState({
                    isExporting: false
                }));

            }else if(type==="csv"){

                let csvHeaders = [major.label + ' | ' + this.state.selectedSubOption.label + ' | ' + (this.state.selectedIndustry.label === 'all' ? 'All Industries' : this.state.selectedIndustry.label) + ' | ' + (this.state.selectedOrgSize.label === 'all' ? 'All Org Sizes' : this.state.selectedOrgSize.label) + ' | ' + (this.state.selectedLocation.label === 'all' ? 'All Locations' : this.state.selectedLocation.label)]
                for (let key in this.getColumnsHeaders()) {
                    csvHeaders.push(this.getColumnsHeaders()[key])
                }
                csvHeaders.push("Trend")

                let exportingData = [csvHeaders]

                for(let dt in this.state.currentData){
                    let row = []
                    let dto = this.state.currentData[dt]
                    row.push(dto.type_label)
                    for (let key in this.getColumnsHeaders()) {
                        row.push(dto[key])
                    }
                    row.push(dto.indicator)
                    exportingData.push(row)
                }
                log('export csv',  exportingData)

                let agre = ""
                agre += agreement.replace(/<\/p>/g, '\n').replace(/<[^>]+>/g, '').replace(/,/g, '')
                exportingData.push([''],[ agre ])

                this.setState({
                    csvData: exportingData,
                    csvDataFileName: fileName + '.csv'
                })

                document.getElementById('csvDataLink').click();

                this.setState({
                    isExporting: false
                })
            }
        }, 100)        
        
    }

    getExportingState(){
        return this.state.isExporting
    }

    render() { 
        const { selectedOption, selectedSubOption, selectedIndustry, selectedOrgSize, selectedLocation, currentData  } = this.state;
        
        const isSearchable = () => {
            return (window.innerWidth < 768) ? false : true
        }
        
        return (
        <div className={"container-fluid" + " is-exporting-" + this.getExportingState() } >
            <UrlStylesComp />
            <div className="row mt-3 g-3 align-items-centerX dropdowns ">
                
                <div className="col-sm-5 col-md-3 select-cats" data-value={""} data-wtf={""}>
                    <Select isSearchable={isSearchable()} closeMenuOnSelect options={this.getSubMajorCats()} components={{ Option: CustomOption }} onChange={this.handleSubCatChange.bind(this)} value={ selectedSubOption } classNamePrefix="ddctrl" placeholder={<FontAwesomeIcon icon={faSpinner} className="fa-spin"  />} />
                </div>

                {urlParamAreaSelect && <div className="col-sm-5 col-md-3 order-first select-majors">
                    <Select isSearchable={isSearchable()} closeMenuOnSelect options={this.getMajorCats()} components={{ Option: CustomOption }}  onChange={this.handleCatChange.bind(this)} value={selectedOption || urlParamAreaObject || this.getMajorCats()[0]} classNamePrefix="ddctrl"
                    />
                </div>}

                <div className="col-sm-5 col-md-3 select-industries" data-value={selectedIndustry.label}>
                    <Select isSearchable={isSearchable()} closeMenuOnSelect options={this.getIndustries()} components={{ Option: CustomOption }} onChange={this.handleIndustryChange.bind(this)} value={selectedIndustry} classNamePrefix="ddctrl" /> 
                </div>
                
                <div className="col-sm-5 col-md-2 select-orgsizes" data-value={selectedOrgSize.label}>
                    <Select isSearchable={isSearchable()} closeMenuOnSelect options={this.getOrgSize()} components={{ Option: CustomOption }} onChange={this.handleOrgSizeChange.bind(this)} value={selectedOrgSize} classNamePrefix="ddctrl" /> 
                </div>

                <div className="col-sm-5 col-md-2 select-location" data-value={selectedLocation.label} >
                    <Select isSearchable={isSearchable()} closeMenuOnSelect options={this.getLocation()} menuIsOpenX={true} components={{ Option: CustomOption }} onChange={this.handleLocationChange.bind(this)} value={selectedLocation} placeholder="Select Location" classNamePrefix="ddctrl" />
                </div>

                <div className='col-sm-2 col-md-2 d-flex justify-content-md-end align-items-end order-last ms-md-auto'>

                    <Dropdown id="dropdown-export-button" title="Export" className="btn-export" onSelect={this.downloader} >
                        <Dropdown.Toggle variant="primary" id="dropdown-export">
                            <span>Export</span>
                            <FontAwesomeIcon
                                icon={faChevronDown}
                                style={{ marginLeft: '10px' }}
                            />
                            <FontAwesomeIcon
                                icon={faCircleNotch}
                                style={{ marginLeft: '10px' }}
                            />
                            
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item eventKey="csv">as CSV</Dropdown.Item>
                            <Dropdown.Item eventKey="img">as Image</Dropdown.Item>
                            <Dropdown.Item eventKey="pdf">as PDF</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    
                </div>
            </div>
            <div className="row">
               <RenderResult id={"render-results"} setStateOfParent = {this.setStateOfParent} options={{ data: {"tHeaders": this.state.tColumns, "currentData": currentData,  "eventCategory": this.state.selectedOption || urlParamAreaObject || this.getMajorCats()[0] , "eventLabel" : this.state.selectedSubOption || this.getSubMajorCats()[0], "selectedIndustry" : this.state.selectedIndustry || 0, "selectedLocation": this.state.selectedLocation || "all", "selectedOrgSize": this.state.selectedOrgSize || 0 } }}    />
               {this.state.csvData && <CSVLink id="csvDataLink" data={this.state.csvData} className="csvLink d-none" filename={this.state.csvDataFileName} target="_blank">Download me</CSVLink>}
            </div>
        </div>
        );
    }
}

export default App 